import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { getBase64 } from "helpers/generic";

import { isNil } from "ramda";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { getAllDomainsNotPaged } from "redux/handlers/domainsHandler";
import { getUserPreferences, postChangeUserPreferences } from "redux/handlers/teamsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ISimpleDomainData } from "types/api/domainsApiInterface";

type preferenceType = {
 domain: string;
 file: string;
 color: string;
};

const SetTeamsPreferences = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [domains, setDomains] = useState<Array<ISimpleDomainData>>([]);
 const [currentLink, setCurrentLink] = useState<string>("");

 const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
 });

 const { control, handleSubmit, setValue, watch, formState, reset } = useForm({
  defaultValues: {
   domain: "test.it",
   file: "",
   color: ""
  }
 });

 const onSubmit: SubmitHandler<preferenceType> = async (data: preferenceType) => {
  setIsLoading(true);
  await dispatch(postChangeUserPreferences(data.file, data.color, data.domain));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = async () => {
  reset();
  const preferences = await dispatch(getUserPreferences());
  if (preferences) {
   setValue("color", preferences?.color);
   setValue("domain", preferences?.domain);
   setValue("file", preferences?.image);
   setCurrentLink(preferences.composition);
  }
  setDomains(await dispatch(getAllDomainsNotPaged()));
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 const checkDisabled = (): boolean => {
  return watch("domain") === "" || watch("file") === "" || watch("color") === "";
 };

 const handleCreateFile = async (files: FileList | null) => {
  if (files && files.length > 0) {
   const result = await getBase64(files[0]);
   setValue("file", result);
  }
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="teams.changePreferences" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={isLoading || checkDisabled()}
    title={intl.formatMessage({ id: "teams.changePreferences" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     {currentLink ? (
      <Typography>
       <FormattedMessage id="teams.linkToAccess" />
       {`: ${currentLink}`}
      </Typography>
     ) : (
      <Alert severity="warning">
       <FormattedMessage id="teams.linkNotFound" />
      </Alert>
     )}
     <Divider sx={{ mt: 2 }} />
     <Controller
      name="domain"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         isNil(value) ? setValue("domain", "") : setValue("domain", value.label);
        }}
        options={domains.map((element) => {
         return {
          id: element.id,
          label: element.domain
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="teams.domain" />}
          error={formState.isDirty && !!formState?.errors?.domain}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Controller
      name="color"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        fullWidth={true}
        label={intl.formatMessage({ id: "teams.color" })}
        error={formState.isDirty && !!formState?.errors?.color}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.color &&
         intl.formatMessage({ id: "teams.colorError" })
        }
       />
      )}
     />
     <Stack direction="row" spacing={2} alignItems="center">
      {watch("file") !== "" ? (
       <img alt="logo" height={40} src={watch("file")} />
      ) : (
       <Alert severity="info">
        <FormattedMessage id="teams.noImageSelected" />
       </Alert>
      )}
      <Button
       component="label"
       role={undefined}
       variant="contained"
       tabIndex={-1}
       startIcon={<CloudUploadIcon />}>
       <FormattedMessage id="teams.uploadImage" />
       <VisuallyHiddenInput
        type="file"
        onChange={(event) => handleCreateFile(event.target.files)}
       />
      </Button>
      <Typography variant="caption" color="gray">
       <FormattedMessage id="teams.imageSizeSuggestion" />
      </Typography>
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default SetTeamsPreferences;

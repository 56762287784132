import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { websiteContainerCronjobSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postCreateCronjob } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CreateCronjob = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [file, setFile] = useState<string>("");
 const [timingSec, setTimingSec] = useState<string>("*");
 const [timingMin, setTimingMin] = useState<string>("*");
 const [timingHour, setTimingHour] = useState<string>("*");
 const [timingDay, setTimingDay] = useState<string>("*");
 const [timingMonth, setTimingMonth] = useState<string>("*");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);

 const handleOpen = async () => {
  setGoToStepper(false);
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateCronjob(
    id,
    file,
    `"${timingSec} ${timingMin} ${timingHour} ${timingDay} ${timingMonth}"`
   )
  );
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setFile("");
  setTimingSec("*");
  setTimingMin("*");
  setTimingHour("*");
  setTimingDay("*");
  setTimingMonth("*");
  setActiveStep(0);
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <TextField
      autoComplete="off"
      value={file}
      autoFocus
      label={intl.formatMessage({ id: "docker.website.cronjobFile" })}
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => setFile(currentTarget.value)}
      helperText={"es: /var/www/html/cron.php, /var/www/html/cron.sh"}
     />
    );
   case 1:
    return (
     <Stack spacing={1}>
      <TextField
       size="small"
       fullWidth={true}
       value={timingSec}
       label={<FormattedMessage id="sites.cronjob.run_sec" />}
       helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
       InputLabelProps={{ shrink: true }}
       onChange={({ currentTarget }) => setTimingSec(currentTarget.value)}
       autoComplete="off"
      />
      <TextField
       size="small"
       fullWidth={true}
       value={timingMin}
       label={<FormattedMessage id="sites.cronjob.run_min" />}
       helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
       onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      <TextField
       size="small"
       fullWidth={true}
       value={timingHour}
       label={<FormattedMessage id="sites.cronjob.run_hour" />}
       helperText={<FormattedMessage id="sites.cronjob.run_hourHelper" />}
       onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      <TextField
       size="small"
       fullWidth={true}
       value={timingDay}
       label={<FormattedMessage id="sites.cronjob.run_mday" />}
       helperText={<FormattedMessage id="sites.cronjob.run_mdayHelper" />}
       onChange={({ currentTarget }) => setTimingDay(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      <TextField
       size="small"
       fullWidth={true}
       value={timingMonth}
       label={<FormattedMessage id="sites.cronjob.run_month" />}
       helperText={<FormattedMessage id="sites.cronjob.run_monthHelper" />}
       onChange={({ currentTarget }) => setTimingMonth(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
     </Stack>
    );
   case 2:
    return (
     <Stack spacing={1}>
      <Typography>
       <FormattedMessage id="docker.website.checkForData" />
      </Typography>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.cronjobFile" />:
       </Typography>
       <Typography>{file}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.cronjobExecution" />:
       </Typography>
       <Typography>{`${timingSec} ${timingMin} ${timingHour} ${timingDay} ${timingMonth}`}</Typography>
      </Stack>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const checkData = (): boolean => {
  switch (activeStep) {
   case 0:
    return file === "";
   case 1:
    return (
     timingSec === "" ||
     timingMin === "" ||
     timingHour === "" ||
     timingDay === "" ||
     timingMonth === ""
    );
   default:
    return false;
  }
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.website.createCronjob" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createCronjob" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerCronjobSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplainCronjob" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplainCronjob" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplainCronjob" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector
         icon="CronjobIcon"
         props={{ sx: { fontSize: "200px", color: "lightgray" } }}
        />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerCronjobSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <Stack p={2} spacing={2}>
       <RenderStepContent />
       <Stack direction="row" spacing={2} justifyContent="center">
        <Button disabled={activeStep === 0} onClick={handleBack}>
         <FormattedMessage id="app.back" />
        </Button>
        <Button onClick={handleReset}>Reset</Button>
        <Button
         disabled={activeStep === websiteContainerCronjobSteps.length - 1 || checkData()}
         onClick={handleNext}>
         <FormattedMessage id="app.next" />
        </Button>
       </Stack>
      </Stack>
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateCronjob;

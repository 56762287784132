import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { websiteContainerSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getPhpVersions, getRepositorys, postCreateWebsite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerPhp, IDockerRepo } from "types/api/dockerApiInterface";

const CreateNewSite = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [phpVers, setPhpVers] = useState<Array<IDockerPhp>>([]);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [website, setWebsite] = useState<string>("");
 const [selectedVers, setSelectedVers] = useState<string>("");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);

 const handleOpen = async () => {
  setPhpVers(await dispatch(getPhpVersions()));
  setRepository(await dispatch(getRepositorys()));
  setGoToStepper(false);
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateWebsite(id, website, selectedVers, repository[0].name));
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setWebsite("");
  setSelectedVers("");
  setActiveStep(0);
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <TextField
      autoComplete="off"
      value={website}
      autoFocus
      label={intl.formatMessage({ id: "docker.website.siteName" })}
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => setWebsite(currentTarget.value)}
     />
    );
   case 1:
    return (
     <FormControl fullWidth>
      <InputLabel>
       <FormattedMessage id="docker.container.phpVers" />
      </InputLabel>
      <Select
       value={selectedVers}
       label={<FormattedMessage id="docker.container.phpVers" />}
       onChange={(e) => setSelectedVers(e.target.value)}>
       {phpVers.map((element, index) => {
        return (
         <MenuItem value={element.name} key={`php-vers-${index}`}>
          {element.name}
         </MenuItem>
        );
       })}
      </Select>
     </FormControl>
    );
   case 2:
    return (
     <Stack spacing={1}>
      <Typography>
       <FormattedMessage id="docker.website.checkForData" />
      </Typography>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.siteName" />:
       </Typography>
       <Typography>{website}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.container.phpVers" />:
       </Typography>
       <Typography>{selectedVers}</Typography>
      </Stack>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const checkData = (): boolean => {
  switch (activeStep) {
   case 0:
    return website === "";
   case 1:
    return selectedVers === "";
   default:
    return false;
  }
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.website.addSite" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.addSite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplain" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplain" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplain" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector icon="WebIcon" props={{ sx: { fontSize: "200px", color: "lightgray" } }} />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={<FormattedMessage id={label.description} />}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <Stack p={2} spacing={2}>
       <RenderStepContent />
       <Stack direction="row" spacing={2} justifyContent="center">
        <Button disabled={activeStep === 0} onClick={handleBack}>
         <FormattedMessage id="app.back" />
        </Button>
        <Button onClick={handleReset}>Reset</Button>
        <Button
         disabled={activeStep === websiteContainerSteps.length - 1 || checkData()}
         onClick={handleNext}>
         <FormattedMessage id="app.next" />
        </Button>
       </Stack>
      </Stack>
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateNewSite;

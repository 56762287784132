import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postDisinstallPhpAdmin } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

const DatabaseFrame = ({
 id,
 database,
 link
}: {
 id: number;
 database: IDatabaseData;
 link: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const handleRemove = async () => {
  await dispatch(postDisinstallPhpAdmin(id));
 };

 return (
  <Stack spacing={2}>
   <Stack>
    <Typography>
     <FormattedMessage id="docker.website.databaseName" />
     {`: ${database.db_name}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databaseUser" />
     {`: ${database.db_user}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePassword" />
     {`: ${database.db_password}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePasswordRoot" />
     {`: ${database.db_root_password}`}
    </Typography>
   </Stack>
   <Stack direction="row">
    <Button onClick={handleRemove}>
     <FormattedMessage id="docker.website.removePhpMyAdmin" />
    </Button>
    <Button onClick={() => window.open(link, "Database", "width=1000, height=800")}>
     <FormattedMessage id="docker.website.openDatabase" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DatabaseFrame;

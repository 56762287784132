import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import LoadBalancerActions from "components/addons/loadBalancer/LoadBalancerActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { balancerTableData } from "types/global/table";

const LoadBalancerTable = ({
 rowData,
 index
}: {
 rowData: balancerTableData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 const handleNavigate = (id: number) => {
  rowData.service_status !== "expired" && navigate(`/addons/loadBalancer/manage/${id}/details`);
 };

 const RenderSystemStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   case "expiring":
    return (
     <IconSelector icon="ReportIcon" props={{ style: { color: "yellow" }, fontSize: "small" }} />
    );
   case "expired":
    return (
     <IconSelector
      icon="ReportProblemIcon"
      props={{ style: { color: "red" }, fontSize: "small" }}
     />
    );
   default:
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
  }
 };

 return (
  <TableRow
   sx={{ cursor: "pointer" }}
   key={`volumes-list-${index}`}
   onClick={() => handleNavigate(rowData.id)}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" alignItems="center" spacing={1}>
      <RenderSystemStatus status={rowData.service_status} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.name}
      </Typography>
     </Stack>
     {RenderLocation(rowData.location_name.toLowerCase())}
     <Stack onClick={(event) => event.stopPropagation()}>
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="caption">{rowData.algorithm}</Typography>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     {rowData.private_net.map((element, index) => {
      return (
       <Typography key={`private-net-${index}`} variant="caption">
        {element.ip || "Not available"}
       </Typography>
      );
     })}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="caption">{rowData.public_net_ipv4_ip || "Not available"}</Typography>
   </TableCell>
   <TableCell onClick={(e) => e.stopPropagation()}>
    <ChangeProtection id={rowData.id} protection={rowData.protection_delete} type="loadBalancer" />
   </TableCell>
   <TableCell>
    <FormattedDate value={rowData.expire} />
   </TableCell>
   <TableCell onClick={(e) => e.stopPropagation()}>
    <LoadBalancerActions rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default LoadBalancerTable;

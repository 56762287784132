import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import FilemanagerFrame from "components/shared/externalApps/FilemanagerFrame";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postInstallFilemanager } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getFilemanagerSession } from "redux/selectors/sessionSelector";

import { useAppDispatch } from "hooks/reduxHook";

const Filemanager = ({ id, active }: { id: number; active: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const sessionActive = useSelector(getFilemanagerSession);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(true);
 const [password, setPassword] = useState<string>("");
 const [port, setPort] = useState<number>(0);

 const handleOpen = async () => {
  setLoading(true);
  setOpen(true);
  if (active || sessionActive === id) await handleLoadData();
  setLoading(false);
 };
 const handleClose = async () => setOpen(false);

 const handleLoadData = async () => {
  setLoading(true);
  const result = await dispatch(postInstallFilemanager(id));
  if (result) {
   setPassword(result.password);
   setPort(result.port);
  }
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.showFilemanager" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector
      icon="FolderIcon"
      props={{ color: active || sessionActive === id ? "success" : "error" }}
     />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.showFilemanager" })}
    handleClose={handleClose}
    handleConfirm={handleClose}
    showConfirmButton={false}>
    {loading ? (
     <Stack direction="row" alignItems="center" spacing={2}>
      <CircularProgress />
      <Typography>
       <FormattedMessage id="docker.website.loadingFilemanagerData" />
      </Typography>
     </Stack>
    ) : active || sessionActive === id ? (
     <FilemanagerFrame id={id} password={password} link={`http://${server.ipv4}:${port}`} />
    ) : (
     <Stack>
      <Alert severity="warning">
       <FormattedMessage id="docker.website.filemanagerOff" />
      </Alert>
      <Stack direction="row">
       <Button onClick={handleLoadData}>
        <FormattedMessage id="docker.website.activateFilemanager" />
       </Button>
      </Stack>
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default Filemanager;

export const agentLastUpdateMinutes = 30;
export const serverOs = ["debian", "ubuntu", "rocky", "centos", "fedora", "windows", "pfsense"];
export const serverOrderStatus = [
 "create",
 "ordering",
 "initialize",
 "ipv4 dns ptr update",
 "ipv6 dns ptr update",
 "backup setup",
 "phase1 - update hosts file",
 "phase2 - system update",
 "phase3 - reset password",
 "phase4 - set timezone",
 "phase5 - install konsolex",
 "finalize"
];
export const serverOrderStatusWebMailNs = [
 "phase1 - install",
 "installing",
 "granting access",
 "success"
];
export const serverOrderStatusContainer = [
 "phase1 - apt update",
 "phase2 - install prerequisites",
 "phase3 - setup keyrings",
 "phase4 - setup repository",
 "phase5 - install container",
 "success"
];
export const serverOrderStatusGateway = [
 "phase1 - add repository",
 "phase2 - add pgp key",
 "phase3 - update",
 "phase4 - set mailname",
 "phase5 - install",
 "phase6 - upgrade",
 "success"
];

export const serverProduct = [
 {
  type: "web",
  id: "server.product.web"
 },
 {
  type: "mail",
  id: "server.product.mail"
 },
 {
  type: "ns",
  id: "server.product.zone"
 },
 {
  type: "zimbra",
  id: "server.product.zimbra"
 },
 {
  type: "gateway",
  id: "server.product.gateway"
 },
 {
  type: "voip",
  id: "server.product.voip"
 },
 {
  type: "custom",
  id: "server.product.custom"
 }
];

export const hostTypeList = [
 {
  type: "web",
  label: "Web"
 },
 {
  type: "mail",
  label: "Mail"
 },
 {
  type: "ns",
  label: "NameServer"
 }
];

export const steps = [
 {
  label: "server.add.selectName",
  description: "server.add.nameDescription"
 },
 {
  label: "server.add.selectProvider",
  description: "server.add.selectProviderMessage"
 },
 {
  label: "server.add.selectServerType",
  description: "server.add.selectServerTypeMessage"
 },
 {
  label: "server.add.selectOs",
  description: "server.add.selectOsMessage"
 },
 {
  label: "server.add.selectLocation",
  description: "server.add.selectLocationMessage"
 },
 {
  label: "server.add.selectType",
  description: "server.add.selectTypeMessage"
 },
 {
  label: "server.add.enableIp",
  description: "server.add.enableIpMessage"
 },
 {
  label: "server.add.enableBackup",
  description: "server.add.enableBackupMessage"
 }
];

export const websiteContainerSteps = [
 {
  label: "docker.website.siteName",
  description: "docker.website.siteNameMessage"
 },
 {
  label: "docker.website.phpVers",
  description: "docker.website.phpVersMessage"
 },
 {
  label: "docker.website.recap",
  description: "docker.website.recapMessage"
 }
];

export const websiteContainerFtpSteps = [
 {
  label: "docker.website.ftpUsername",
  description: ""
 },
 {
  label: "docker.website.ftpPassword",
  description: ""
 },
 {
  label: "docker.website.recap",
  description: "docker.website.recapMessage"
 }
];

export const websiteContainerCronjobSteps = [
 {
  label: "docker.website.cronjobFile",
  description: ""
 },
 {
  label: "docker.website.cronjobExecution",
  description: ""
 },
 {
  label: "docker.website.recap",
  description: "docker.website.recapMessage"
 }
];

export const websiteContainerDatabaseSteps = [
 {
  label: "docker.website.databaseName",
  description: ""
 },
 {
  label: "docker.website.databaseUser",
  description: ""
 },
 {
  label: "docker.website.databasePassword",
  description: ""
 },
 {
  label: "docker.website.recap",
  description: "docker.website.recapMessage"
 }
];

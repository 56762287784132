import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import DockerOrdersReducer from "redux/reducers/dockerOrderReducer";
import DockerWebsiteReducer from "redux/reducers/dockerWebsiteReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import SessionReducer from "redux/reducers/sessionReducer";

import {
 IContainerAPI,
 IContainerData,
 ICronjobData,
 IDatabaseData,
 IDockerAPI,
 IDockerNetworkAPI,
 IDockerOrdersAPI,
 IDockerPhp,
 IDockerPorts,
 IDockerRepo,
 IDockerServiceAPI,
 IDockerSite,
 IDockerSiteAPI,
 IDockerStackAPI,
 IDockerVolumeAPI,
 IFilemanagerData,
 IFtpData
} from "types/api/dockerApiInterface";

import { ApiService } from "service/ApiService";

export const getDockerOrders =
 (page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerOrdersAPI>(
    `/docker/order/getorders?page=${page}&limit=${limit}&q=${q || ""}`
   );
   dispatch(DockerOrdersReducer.actions.setDockerOrders(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load docker orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getDockerImages =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerAPI>(
    `/docker/image/getimages?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load images - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerContainers =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IContainerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerAPI>(
    `/docker/container/getcontainers?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerProxy =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IContainerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerAPI>(
    `/docker/container/getcontainers?id_server=${id}&page=${page}&limit=${limit}&q=${
     q || ""
    }&type=proxy`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerNetworks =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerNetworkAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerNetworkAPI>(
    `/docker/network/getnetworks?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load networks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerVolumes =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerVolumeAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerVolumeAPI>(
    `/docker/volume/getvolumes?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load volumes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerStacks =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerStackAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerStackAPI>(
    `/docker/stack/getstacks?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load stacks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerServices =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerServiceAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerServiceAPI>(
    `/docker/service/getservices?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load services - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getServerSites =
 (server_id: number, page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerSiteAPI>(
    `/docker/site/getsites?page=${page}&limit=${limit}&q=${q || ""}&server_id_db=${server_id}`
   );
   dispatch(DockerWebsiteReducer.actions.setDockerWebsites(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getProxySites =
 (proxy_id: string, page: number, limit: number, q?: string): AppAction<Promise<IDockerSiteAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerSiteAPI>(
    `/docker/site/getsites?page=${page}&limit=${limit}&q=${q || ""}&proxy_id=${proxy_id}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getPhpVersions = (): AppAction<Promise<Array<IDockerPhp>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerPhp>>("/docker/php/getversions");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get php versions - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getRepositorys = (): AppAction<Promise<Array<IDockerRepo>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerRepo>>("/docker/repo/getrepo");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get repository - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getPorts = (): AppAction<Promise<Array<IDockerPorts>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerPorts>>("/docker/port/getports");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get ports - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getAllSites =
 (id: number): AppAction<Promise<Array<IDockerSite>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IDockerSite>>("/docker/site/getbyserverid", "POST", {
    server_id_db: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getAllContainers =
 (id: number): AppAction<Promise<Array<IContainerData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IContainerData>>(
    "/docker/container/getbyserverid",
    "POST",
    {
     server_id_db: id
    }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateContainer =
 (id: number, name: string, repo: string, php_id: string, site: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/installcontainer", "POST", {
    server_id_db: id,
    container_name: name,
    repo_name: repo,
    php_id: php_id,
    site_name: site
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing container",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install container - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteContainer =
 (id: number, container_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/disinstallcontainer", "POST", {
    server_id_db: id,
    id: container_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting container",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete container - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckForProxy =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/checkproxy", "POST", {
    server_id_db: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postInstallProxy =
 (id: number, name: string, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/installproxy", "POST", {
    server_id_db: id,
    container_name: name,
    type: type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableProxy =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/disableproxy", "POST", {
    server_id_db: id,
    container_name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Disable proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRedirectProxy =
 (id: number, name: string, port: number, domain: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>(
    "/docker/container/redirectproxytocontainer",
    "POST",
    {
     server_id_db: id,
     container_name: name,
     port: port,
     domain_name: domain
    }
   );
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Redirecting proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to redirect proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateSite =
 (id: number, name: string, proxy_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/createsite", "POST", {
    server_id_db: id,
    site_name: name,
    proxy_id: proxy_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postApplySsl =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/applyssl", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Applying ssl",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to apply ssl - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteSite =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/deletesite", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteAllDataSite =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/deletewithcontainer", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateWebsite =
 (id: number, name: string, php_version: string, repository: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/installfullsite", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    port: 80,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy"
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallFilemanager =
 (id: number): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/activefilemanager", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "filemanager", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install filemanager - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postDisinstallFilemanager =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService<boolean>("/docker/site/deactivatefilemanager", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "filemanager", value: 0 }));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install filemanager - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGetDatabaseData =
 (container_id: string): AppAction<Promise<Array<IDatabaseData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IDatabaseData>>("/docker/site/db/list", "POST", {
    container_id: container_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install filemanager - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postInstallDatabase =
 (
  id: number,
  db_name: string,
  db_user: string,
  db_password: string
 ): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/installdb", "POST", {
    id,
    db_name,
    db_user,
    db_password
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postUninstallDatabase =
 (id: number): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/disinstalldb", "POST", {
    id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postInstallPhpAdmin =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/installphpmyadmin", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install PHP admin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postDisinstallPhpAdmin =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService<boolean>("/docker/site/db/disinstallphpmyadmin", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: 0 }));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall PHP admin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllCronjob =
 (container_id: string): AppAction<Promise<Array<ICronjobData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ICronjobData>>("/docker/site/cronjob/list", "POST", {
    container_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateCronjob =
 (id: number, file: string, schedule: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/cronjob/add", "POST", {
    id: id,
    file: file,
    schedule: schedule
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating cronjob",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCronjob =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/cronjob/delete", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting cronjob",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllFtpUsers =
 (id: number): AppAction<Promise<Array<IFtpData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IFtpData>>("/docker/site/ftp/list", "POST", {
    id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get ftp users - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateFtpUser =
 (id: number, ftp_user: string, ftp_password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/create", "POST", {
    id: id,
    ftp_user: ftp_user,
    ftp_password: ftp_password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating ftp user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableFtp =
 (server_id_db: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/deactivate", "POST", {
    server_id_db: server_id_db
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Disabling ftp",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable ftp - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteFtpUser =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/delete", "POST", {
    id_ftp: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting ftp user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

import React, { ReactElement, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { getAllAddons } from "redux/handlers/addonsHandle";
import { getAllDomainsNotPaged } from "redux/handlers/domainsHandler";
import { getAllMailsNotPaged } from "redux/handlers/mailsHandler";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";
import { getUserSettings, postChangeUserSettings } from "redux/handlers/teamsHandler";
import { getAllWebsitesNotPaged } from "redux/handlers/websitesHandler";
import { getAllZoneNotPaged } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllAddonsAPI } from "types/api/addonsApiInterface";
import { IZoneList } from "types/api/appsApiInterface";
import { ISimpleDomainData } from "types/api/domainsApiInterface";
import { IMailNotPagedData } from "types/api/mailApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import { IAllWebsiteData } from "types/api/websitesApiInterface";
import { settingsType } from "types/global/user";

import AddonsSection from "./settingsSections/AddonsSection";
import AppsSection from "./settingsSections/AppsSection";
import DomainsSection from "./settingsSections/DomainsSections";
import ServersSection from "./settingsSections/ServersSection";

type sectionsType =
 | "cloudboxes"
 | "volumes"
 | "load_balancers"
 | "floating_ips"
 | "snapshots"
 | "networks"
 | "firewalls"
 | "domains"
 | "servers"
 | "sites"
 | "mails"
 | "zones";

const UpdateUserSettings = ({
 id,
 closeMenu
}: {
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loadingData, setLoadingData] = useState<boolean>(false);
 const [domains, setDomains] = useState<Array<ISimpleDomainData>>([]);
 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [sites, setSites] = useState<Array<IAllWebsiteData>>([]);
 const [mails, setMails] = useState<Array<IMailNotPagedData>>([]);
 const [zone, setZone] = useState<Array<IZoneList>>([]);
 const [addons, setAddons] = useState<IAllAddonsAPI | null>(null);

 const { control, handleSubmit, setValue, watch, reset } = useForm({
  defaultValues: {
   domains: [],
   servers: [],
   sites: [],
   mails: [],
   zones: [],
   cloudboxes: [],
   volumes: [],
   floating_ips: [],
   load_balancers: [],
   networks: [],
   snapshots: [],
   firewalls: [],
   showAuthinfo: false,
   showDns: false,
   showOwners: false,
   showNs: false,
   editAuthinfo: false,
   editDns: false,
   editNs: false,
   editRebootServer: false,
   editPowerOffOnServer: false,
   showBackupServer: false,
   editBackupServer: false,
   editRebuildServer: false,
   editPowerOffOnSite: false,
   editPowerOffOnMail: false,
   editPowerOffOnZone: false,
   showZoneRecords: false,
   editZoneRecords: false
  } as settingsType
 });

 const onSubmit: SubmitHandler<settingsType> = async (data: settingsType) => {
  setIsLoading(true);
  await dispatch(postChangeUserSettings(id, data));
  setIsLoading(false);
  handleClose();
 };

 const handleOpen = async () => {
  reset();
  setLoadingData(true);
  const preferences = await dispatch(getUserSettings(id));
  if (preferences) {
   setValue("domains", preferences.domains);
   setValue("servers", preferences.servers);
   setValue("sites", preferences.sites);
   setValue("mails", preferences.mails);
   setValue("zones", preferences.zones);
   setValue("showAuthinfo", preferences.showAuthinfo);
   setValue("showDns", preferences.showDns);
   setValue("showOwners", preferences.showOwners);
   setValue("showNs", preferences.showNs);
   setValue("editAuthinfo", preferences.editAuthinfo);
   setValue("editDns", preferences.editDns);
   setValue("editNs", preferences.editNs);
   setValue("editRebootServer", preferences.editRebootServer);
   setValue("editPowerOffOnServer", preferences.editPowerOffOnServer);
   setValue("editBackupServer", preferences.editBackupServer);
   setValue("showBackupServer", preferences.showBackupServer);
   setValue("editRebuildServer", preferences.editRebuildServer);
   setValue("editPowerOffOnSite", preferences.editPowerOffOnSite);
   setValue("editPowerOffOnMail", preferences.editPowerOffOnMail);
   setValue("editPowerOffOnZone", preferences.editPowerOffOnZone);
   setValue("showZoneRecords", preferences.showZoneRecords);
   setValue("editZoneRecords", preferences.editZoneRecords);
   setValue("cloudboxes", preferences.cloudboxes);
   setValue("volumes", preferences.volumes);
   setValue("networks", preferences.networks);
   setValue("firewalls", preferences.firewalls);
   setValue("snapshots", preferences.snapshots);
   setValue("load_balancers", preferences.load_balancers);
   setValue("floating_ips", preferences.floating_ips);
  }
  setDomains(await dispatch(getAllDomainsNotPaged()));
  setServers(await dispatch(getAllServersNotPaged()));
  setAddons(await dispatch(getAllAddons()));
  setSites(await dispatch(getAllWebsitesNotPaged()));
  setMails(await dispatch(getAllMailsNotPaged()));
  setZone(await dispatch(getAllZoneNotPaged()));
  setLoadingData(false);
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
  closeMenu();
 };

 const checkDisabled = (): boolean => {
  return watch("domains").length === 0 && watch("servers").length === 0;
 };

 const handleControlSelection = (
  section: sectionsType,
  addin: Array<{ id: number; label: string }>
 ) => {
  const addins = addin.map((element) => {
   return element.id;
  });
  setValue(section, addins);
 };

 const handleSetApp = (section: string, value: Array<number>) => {
  switch (section) {
   case "sites":
    setValue("sites", value);
    break;
   case "mails":
    setValue("mails", value);
    break;
   case "zones":
    setValue("zones", value);
    break;
   default:
    setValue("sites", value);
    break;
  }
 };

 return (
  <>
   <MenuItem disabled={loadingData} onClick={handleOpen}>
    <Typography>
     <FormattedMessage id="teams.updateUserSettings" />
    </Typography>
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={isLoading || checkDisabled()}
    title={intl.formatMessage({ id: "teams.updateUserSettings" })}
    customSize={{
     overflowY: "scroll"
    }}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Alert severity="info" sx={{ mb: 2 }}>
      <FormattedMessage id="teams.usersettings.explainations" />
     </Alert>
     <Stack>
      <DomainsSection
       control={control}
       domains={domains}
       selectedDomains={watch("domains")}
       domainPermissions={{
        showAuthinfo: watch("showAuthinfo"),
        showDns: watch("showDns"),
        showOwners: watch("showOwners"),
        showNs: watch("showNs"),
        editAuthinfo: watch("editAuthinfo"),
        editDns: watch("editDns"),
        editNs: watch("editNs")
       }}
       setDomainsList={(domains) => setValue("domains", domains)}
       addDomain={(domain) => handleControlSelection("domains", domain)}
       setPermission={(section, value) => setValue(section, value)}
      />
      <ServersSection
       control={control}
       servers={servers}
       selectedServers={watch("servers")}
       serverPermissions={{
        editRebootServer: watch("editRebootServer"),
        editPowerOffOnServer: watch("editPowerOffOnServer"),
        showBackupServer: watch("showBackupServer"),
        editBackupServer: watch("editBackupServer"),
        editRebuildServer: watch("editRebuildServer")
       }}
       setServers={(servers) => setValue("servers", servers)}
       addServer={(server) => handleControlSelection("servers", server)}
       setPermission={(section, value) => setValue(section, value)}
      />
      <AppsSection
       control={control}
       sites={sites}
       mails={mails}
       zone={zone}
       selectedSites={watch("sites")}
       selectedMails={watch("mails")}
       selectedZone={watch("zones")}
       appsPermissions={{
        editPowerOffOnSite: watch("editPowerOffOnSite"),
        editPowerOffOnMail: watch("editPowerOffOnMail"),
        editPowerOffOnZone: watch("editPowerOffOnZone"),
        showZoneRecords: watch("showZoneRecords"),
        editZoneRecords: watch("editZoneRecords")
       }}
       setApp={(section, value) => handleSetApp(section, value)}
       addApp={(section, value) => handleControlSelection(section, value)}
       setPermission={(section, value) => setValue(section, value)}
      />
      <AddonsSection
       control={control}
       addons={addons}
       selectedAddons={{
        cloudboxes: watch("cloudboxes"),
        volumes: watch("volumes"),
        snapshots: watch("snapshots"),
        firewalls: watch("firewalls"),
        floating_ips: watch("floating_ips"),
        networks: watch("networks"),
        load_balancers: watch("load_balancers")
       }}
       setAddons={(section, value) => setValue(section, value)}
       addAddons={(section, value) => handleControlSelection(section, value)}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default UpdateUserSettings;

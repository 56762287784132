import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
 FormattedDate,
 FormattedMessage,
 FormattedNumber,
 FormattedTime,
 useIntl
} from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import RenderPerformance from "components/shared/progressBar/RenderPerformance";

import { getServerDetails } from "redux/selectors/serversSelector";

import { AppContext } from "../../../../AppContext";
import ServerVnc from "../../ServerVNC";

import LockServer from "./monitoringActions/LockServer";
import PowerServer from "./monitoringActions/PowerServer";
import RebootServer from "./monitoringActions/RebootServer";
import ServerIso from "./monitoringActions/ServerIso";

type serverLogData = {
 cpu: number;
 disk: number;
 inode: number;
 logs: number;
 ram: number;
 rx: number;
 tx: number;
 tz: string;
};

const CircularMonitor = ({
 value,
 label,
 icon
}: {
 value: number;
 label: string;
 icon: string;
}): ReactElement => {
 const circularColor = (): string => {
  if (value <= 50) {
   return "green";
  } else if (value > 50 && value <= 80) {
   return "orange";
  } else {
   return "red";
  }
 };

 return (
  <Box
   display="flex"
   minWidth={80}
   justifyContent="center"
   alignItems="center"
   sx={{ position: "relative", textAlign: "center", height: 80 }}>
   <CircularProgress
    value={100}
    size="5rem"
    sx={{ color: "#dcdada", position: "absolute" }}
    variant="determinate"
   />
   <CircularProgress
    variant="determinate"
    size="5rem"
    sx={{ color: circularColor(), animationDuration: "550ms", position: "absolute" }}
    value={value}
   />
   <Stack position="absolute" alignItems="center">
    <IconSelector icon={icon} props={{ fontSize: "small" }} />
    <Typography variant="extraSmall" fontWeight="bold" textTransform="uppercase" fontSize={10}>
     {label}:{value}%
    </Typography>
   </Stack>
  </Box>
 );
};

const ServerMonitoring = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const server = useSelector(getServerDetails);

 const [logData, setLogData] = useState<serverLogData>({
  cpu: 0,
  disk: 0,
  inode: 0,
  logs: 0,
  ram: 0,
  rx: 0,
  tx: 0,
  tz: ""
 });

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setLogData({
        cpu: parseInt(value[i]?.cpu) || 0,
        disk: parseInt(value[i]?.disk) || 0,
        inode: parseInt(value[i]?.inode) || 0,
        logs: parseInt(value[i]?.logs) || 0,
        ram: parseInt(value[i]?.ram) || 0,
        rx: parseInt(value[i]?.rx) || 0,
        tx: parseInt(value[i]?.tx) || 0,
        tz: value[i]?.tz || ""
       });
      }
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 return (
  <Paper
   elevation={0}
   sx={{ pt: 1, mt: desktopViewPort ? 0 : 2, borderRadius: "10px", boxShadow: 0 }}>
   <Grid container>
    <Grid xs={3}>
     {desktopViewPort && (
      <Stack mt={2}>
       <Typography
        variant="extraSmall"
        textAlign="start"
        fontWeight="bold"
        textTransform="uppercase"
        sx={{ pl: 1 }}>
        <FormattedMessage id="app.actions" />
       </Typography>

       <Stack direction="row" spacing={1} justifyContent="flex-start">
        {desktopViewPort && <ServerVnc />}
        <ServerIso id={server.id || 0} iso_id={server.iso_id || 0} />
        <RebootServer id={server.id || 0} service_status={server.service_status || ""} />
        <LockServer id={server.id || 0} locked={server.locked || false} />
       </Stack>
      </Stack>
     )}
     <Stack pl={1} pb={1}>
      <Stack direction="row">
       <Typography
        variant="caption"
        alignItems="flex-end"
        display="flex"
        textTransform="uppercase"
        fontWeight="bold"
        mr={1}>
        <FormattedMessage id="server.manage.outgoingTraffic" />
       </Typography>
       <Typography fontSize="small" lineHeight={1.43}>
        <FormattedNumber value={logData.tx} unit="gigabyte" />
       </Typography>
       <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
        <FormattedMessage id="server.manage.mb" />
       </Typography>
      </Stack>
      <Stack direction="row">
       <Typography
        variant="caption"
        alignItems="flex-end"
        display="flex"
        textTransform="uppercase"
        fontWeight="bold"
        mr={1}>
        <FormattedMessage id="server.manage.incomingTraffic" />
       </Typography>
       <Typography fontSize="small" lineHeight={1.43}>
        <FormattedNumber value={logData.rx} unit="gigabyte" />
       </Typography>
       <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
        <FormattedMessage id="server.manage.mb" />
       </Typography>
      </Stack>
      {desktopViewPort && (
       <Stack direction="row">
        <Typography
         variant="caption"
         alignItems="flex-end"
         display="flex"
         textTransform="uppercase"
         fontWeight="bold"
         mr={1}>
         <FormattedMessage id="server.manage.lastData" />
        </Typography>
        <Stack direction={"row"} spacing={1}>
         {logData.tz ? (
          <>
           <Typography fontSize="small" lineHeight={1.43}>
            <FormattedDate value={new Date(logData.tz)} />
           </Typography>
           <Typography fontSize="small" lineHeight={1.43}>
            <FormattedTime value={new Date(logData.tz)} />
           </Typography>
          </>
         ) : (
          <Typography fontSize="small" lineHeight={1.43}>
           <FormattedMessage id="server.manage.chargingData" />
          </Typography>
         )}
        </Stack>
       </Stack>
      )}
     </Stack>
     {!desktopViewPort && (
      <Stack direction="row" pl={1} pb={1} pt="50%" alignItems="center">
       <Typography
        variant="caption"
        alignItems="flex-end"
        display="flex"
        textTransform="uppercase"
        fontWeight="bold"
        mr={1}>
        <FormattedMessage id="server.manage.lastData" />
       </Typography>
       <Stack direction={"row"} spacing={1}>
        {logData.tz ? (
         <>
          <Typography fontSize="small" lineHeight={1.43}>
           <FormattedDate value={new Date(logData.tz)} />
          </Typography>
          <Typography fontSize="small" lineHeight={1.43}>
           <FormattedTime value={new Date(logData.tz)} />
          </Typography>
         </>
        ) : (
         <Typography fontSize="small" lineHeight={1.43}>
          <FormattedMessage id="server.manage.chargingData" />
         </Typography>
        )}
       </Stack>
      </Stack>
     )}
    </Grid>
    <Grid xs={9}>
     <Stack direction="row" justifyContent="flex-end">
      <Stack direction="row" spacing={2}>
       <Stack alignItems="center" justifyContent="center">
        {desktopViewPort && (
         <CircularMonitor
          value={logData.cpu}
          label={intl.formatMessage({ id: "server.cpu" })}
          icon="CpuIcon"
         />
        )}
        {desktopViewPort && (
         <>
          <Stack justifyContent="center" alignItems="center">
           <Typography variant="extraSmall" textTransform="uppercase">
            <FormattedMessage id="server.cpu" />:{server.cpuCore}{" "}
            <FormattedMessage id="server.core" />
           </Typography>
          </Stack>
         </>
        )}
       </Stack>
       <Stack alignItems="center" justifyContent="flex-start">
        {desktopViewPort && (
         <CircularMonitor
          value={logData.ram}
          label={intl.formatMessage({ id: "server.ram" })}
          icon="RamIcon"
         />
        )}
        {desktopViewPort && (
         <>
          <Stack justifyContent="center" alignItems="center">
           <Typography variant="extraSmall" textTransform="uppercase">
            {isNil(server.ramSize) ? (
             "N/A"
            ) : (
             <>
              <FormattedMessage id="server.ram" />:{server.ramSize}
              <FormattedMessage id="server.gigabytes.short" />
             </>
            )}
           </Typography>
          </Stack>
         </>
        )}
       </Stack>
       <Stack alignItems="center" justifyContent="flex-end">
        {desktopViewPort && (
         <CircularMonitor
          value={logData.disk}
          label={intl.formatMessage({ id: "server.nvme" })}
          icon="DiscIcon"
         />
        )}
        {desktopViewPort && (
         <>
          <Stack justifyContent="center" alignItems="center">
           <Typography variant="extraSmall" textTransform="uppercase">
            <FormattedMessage id="server.disk" />:
            {isNil(server.diskSize) ? (
             "N/A"
            ) : (
             <>
              {server.diskSize}
              <FormattedMessage id="server.gigabytes.short" />
             </>
            )}
           </Typography>
          </Stack>
         </>
        )}
       </Stack>
      </Stack>
      <Stack
       minWidth={desktopViewPort ? "30%" : "100%"}
       ml={desktopViewPort ? 10 : 0}
       justifyContent="center">
       {!desktopViewPort && (
        <>
         <RenderPerformance value={logData.cpu} label={intl.formatMessage({ id: "server.cpu" })} />
         <RenderPerformance value={logData.ram} label={intl.formatMessage({ id: "server.ram" })} />
         <RenderPerformance
          value={logData.disk}
          label={intl.formatMessage({ id: "server.nvme" })}
         />
        </>
       )}
       {server.system_family !== "windows" && (
        <>
         <RenderPerformance
          value={logData.inode}
          label={intl.formatMessage({ id: "server.inodes" })}
         />
         <RenderPerformance
          value={logData.logs}
          label={intl.formatMessage({ id: "server.logs" })}
         />
        </>
       )}
      </Stack>
     </Stack>
     {!desktopViewPort && (
      <Stack direction="row" justifyContent="flex-end" alignItems="center" pr={1}>
       <Typography>
        {server.server_status === "off" ? (
         <FormattedMessage id="server.serverOff" />
        ) : (
         <FormattedMessage id="server.serverOn" />
        )}
       </Typography>
       <PowerServer
        id={server.id || 0}
        status={server.server_status || "off"}
        service_status={server.service_status || ""}
       />
       <RebootServer id={server.id || 0} service_status={server.service_status || ""} />
      </Stack>
     )}
     {desktopViewPort && (
      <Stack direction="row" justifyContent="flex-end" alignItems="center" pr={1}>
       <Typography>
        {server.server_status === "off" ? (
         <FormattedMessage id="server.serverOff" />
        ) : (
         <FormattedMessage id="server.serverOn" />
        )}
       </Typography>
       <PowerServer
        id={server.id || 0}
        status={server.server_status || "off"}
        service_status={server.service_status || ""}
       />
      </Stack>
     )}
    </Grid>
   </Grid>
  </Paper>
 );
};

export default ServerMonitoring;

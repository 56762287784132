import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { websiteContainerDatabaseSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postInstallDatabase } from "redux/handlers/dockerHandler";
import { generatePassword } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CreateDatabase = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [dbUser, setDbUser] = useState<string>("");
 const [dbName, setDbName] = useState<string>("");
 const [dbPassword, setDbPassword] = useState<string>("");
 const [showPassword, setShowPassword] = useState<string>("password");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);

 const handleOpen = async () => {
  setGoToStepper(false);
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postInstallDatabase(id, dbName, dbUser, dbPassword));
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setDbName("");
  setDbUser("");
  setDbPassword("");
  setActiveStep(0);
 };

 const handleGeneratePassword = async () => {
  setDbPassword(await generatePassword());
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <TextField
      autoComplete="off"
      value={dbName}
      autoFocus
      onChange={({ currentTarget }) => setDbName(currentTarget.value)}
      fullWidth={true}
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      label={intl.formatMessage({ id: "docker.website.databaseName" })}
     />
    );
   case 1:
    return (
     <TextField
      value={dbUser}
      autoFocus
      onChange={({ currentTarget }) => setDbUser(currentTarget.value)}
      fullWidth={true}
      InputLabelProps={{ shrink: true }}
      autoComplete="new-password"
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      label={intl.formatMessage({ id: "docker.website.databaseUser" })}
     />
    );
   case 2:
    return (
     <Stack direction="row" alignItems="center" spacing={2}>
      <TextField
       value={dbPassword}
       onChange={({ currentTarget }) => setDbPassword(currentTarget.value)}
       fullWidth={true}
       autoFocus
       type={showPassword}
       autoComplete="new-password"
       label={intl.formatMessage({ id: "docker.website.databasePassword" })}
       InputProps={{
        endAdornment: (
         <InputAdornment position="end">
          <IconButton
           aria-label="toggle password visibility"
           onClick={() => setShowPassword(showPassword === "password" ? "text" : "password")}
           edge="end">
           <IconSelector
            icon={showPassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
            props={{ fontSize: "small" }}
           />
          </IconButton>
         </InputAdornment>
        )
       }}
      />
      <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
       <FormattedMessage id="app.generatePassword" />
      </Button>
     </Stack>
    );
   case 3:
    return (
     <Stack spacing={1}>
      <Typography>
       <FormattedMessage id="docker.website.checkForData" />
      </Typography>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.databaseName" />:
       </Typography>
       <Typography>{dbName}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.databaseUser" />:
       </Typography>
       <Typography>{dbUser}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Typography>
        <FormattedMessage id="docker.website.databasePassword" />:
       </Typography>
       <Typography>{dbPassword}</Typography>
      </Stack>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const checkData = (): boolean => {
  switch (activeStep) {
   case 0:
    return dbName === "";
   case 1:
    return dbUser === "";
   case 2:
    return dbPassword === "";
   default:
    return false;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.createDatabase" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createDatabase" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerDatabaseSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplainDatabase" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector
         icon="DatabaseIcon"
         props={{ sx: { fontSize: "200px", color: "lightgray" } }}
        />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerDatabaseSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <Stack p={2} spacing={2}>
       <RenderStepContent />
       <Stack direction="row" spacing={2} justifyContent="center">
        <Button disabled={activeStep === 0} onClick={handleBack}>
         <FormattedMessage id="app.back" />
        </Button>
        <Button onClick={handleReset}>Reset</Button>
        <Button
         disabled={activeStep === websiteContainerDatabaseSteps.length - 1 || checkData()}
         onClick={handleNext}>
         <FormattedMessage id="app.next" />
        </Button>
       </Stack>
      </Stack>
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateDatabase;

import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DatabaseFrame from "components/shared/externalApps/DatabaseFrame";

import { postGetDatabaseData, postInstallPhpAdmin } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getPhpSession } from "redux/selectors/sessionSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

const defaultDatabase = {
 container_id: "",
 db_name: "",
 db_password: "",
 db_root_password: "",
 db_user: "",
 id: 0
};

const PhpMyAdminSection = ({
 id,
 container_id,
 active
}: {
 id: number;
 container_id: string;
 active: boolean;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const sessionActive = useSelector(getPhpSession);

 const server = useSelector(getServerDetails);
 const [loading, setLoading] = useState<boolean>(true);
 const [database, setDatabase] = useState<IDatabaseData | null>(null);

 useEffect(() => {
  (async () => {
   setLoading(true);
   if (active || sessionActive === id) await handleLoadData();
   setLoading(false);
  })();
 }, []);

 const handleLoadData = async () => {
  setLoading(true);
  const result = await dispatch(postInstallPhpAdmin(id));
  if (result && container_id) {
   const dblist = await dispatch(postGetDatabaseData(container_id));
   setDatabase(dblist[0]);
  }
  setLoading(false);
 };

 return (
  <>
   {loading ? (
    <Stack direction="row" alignItems="center" spacing={2}>
     <CircularProgress />
     <Typography>
      <FormattedMessage id="docker.website.loadingDatabaseData" />
     </Typography>
    </Stack>
   ) : active || sessionActive === id ? (
    <DatabaseFrame
     id={id}
     link={`http://${server.ipv4}:8089`}
     database={database || defaultDatabase}
    />
   ) : (
    <Stack>
     <Alert severity="warning">
      <FormattedMessage id="docker.website.phpMyAdminOff" />
     </Alert>
     <Stack direction="row">
      <Button onClick={handleLoadData}>
       <FormattedMessage id="docker.website.activatePhpMyAdmin" />
      </Button>
     </Stack>
    </Stack>
   )}
  </>
 );
};

export default PhpMyAdminSection;

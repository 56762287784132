import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postDisinstallFilemanager } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const FilemanagerFrame = ({
 id,
 password,
 link
}: {
 id: number;
 password: string;
 link: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const handleRemove = async () => {
  await dispatch(postDisinstallFilemanager(id));
 };
 return (
  <Stack spacing={2}>
   <Stack>
    <Typography>
     <FormattedMessage id="docker.website.filemanagerUser" />
     {": admin"}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.filemanagerPassword" />
     {`: ${password}`}
    </Typography>
   </Stack>
   <Stack direction="row">
    <Button onClick={handleRemove}>
     <FormattedMessage id="docker.website.removeFilemanager" />
    </Button>
    <Button onClick={() => window.open(link, "Filemanager", "width=1000, height=800")}>
     <FormattedMessage id="docker.website.openFileManager" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default FilemanagerFrame;

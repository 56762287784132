import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postUninstallDatabase } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RemoveDatabase = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postUninstallDatabase(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.disinstallDatabase" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.disinstallDatabase" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.website.disinstallDatabaseMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RemoveDatabase;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IDockerSiteAPI } from "types/api/dockerApiInterface";

const initialState: IDockerSiteAPI = { dataset: [], totalCount: 0 };

const DockerWebsiteReducer = createSlice({
 name: "Dockerwebsite",
 initialState,
 reducers: {
  setDockerWebsites: (state, { payload }: PayloadAction<IDockerSiteAPI>) => {
   return payload;
  },
  resetDockerWebsites: () => {
   return initialState;
  }
 }
});

export default DockerWebsiteReducer;

import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postGetDatabaseData } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

import RemoveDatabase from "../../actions/RemoveDatabase";

const DatabaseSection = ({
 id,
 container_id
}: {
 id: number;
 container_id: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [databaseList, setDatabaseList] = useState<Array<IDatabaseData>>([]);

 useEffect(() => {
  (async () => {
   if (container_id) {
    const dblist = await dispatch(postGetDatabaseData(container_id));
    setDatabaseList(dblist);
   }
  })();
 }, []);

 return (
  <Stack spacing={2}>
   <Typography fontWeight="bold">
    <FormattedMessage id="docker.website.selectedDatabase" />
    {`: ${databaseList[0]?.db_name || ""}`}
   </Typography>
   <Typography fontWeight="bold">
    <FormattedMessage id="docker.website.selectedDatabaseAccess" />
   </Typography>
   <Stack>
    <Typography>
     <FormattedMessage id="docker.website.databaseName" />
     {`: ${databaseList[0]?.db_name}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databaseUser" />
     {`: ${databaseList[0]?.db_user}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePassword" />
     {`: ${databaseList[0]?.db_password}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePasswordRoot" />
     {`: ${databaseList[0]?.db_root_password}`}
    </Typography>
   </Stack>
   <Stack direction="row">
    <RemoveDatabase id={id} />
   </Stack>
  </Stack>
 );
};

export default DatabaseSection;
